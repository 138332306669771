<template>
  <DashboardTemplate v-loading="loading">
    <Layout>
      <div class="box-product hidden-xs-only" v-if="dataCfoe != null">
        <ul
          class="main"
          v-if="!activeBar && !viewHover"
          @mouseover="!activeBar ? (viewHover = true) : ''"
          @click="
            (tabActive = 'product'),
              (barActive.loan = true),
              (barActive.insurance = true),
              (barActive.project = true)
          "
        >
          <li :style="`height: ${countBar}px;`" class="active">
            <div class="arrow">
              <i class="fas fa-chevron-left"></i>
            </div>
            <div class="icon is-flex">
              <div class="text-white">
                <img src="@/assets/icon-product.png" alt="loan" />
                <span class="mg-l-7"
                  >ผลิตภัณฑ์และโครงการ<br />ที่เหมาะสมสำหรับคุณ</span
                >
              </div>
            </div>
          </li>
        </ul>
        <ul class="sub" v-else @mouseleave="viewHover = false">
          <li
            :class="{ active: barActive.loan }"
            v-if="dataCfoe.credit.length > 0"
          >
            <div
              class="arrow"
              @click="
                (barActive.loan = !barActive.loan),
                  (barActive.insurance = false),
                  (barActive.project = false),
                  (activeBar = !activeBar),
                  (viewHover = false)
              "
            >
              <i
                :class="
                  (viewHover && activeBar) || (!viewHover && !activeBar)
                    ? 'fa-chevron-left'
                    : 'fa-chevron-right'
                "
                class="fas fa-chevron-left"
              ></i>
            </div>
            <div class="icon" @click="tabActive = 'loan'">
              <a href="#section-product" class="text-white">
                <img src="@/assets/icon-loan.png" alt="loan" />
                <span class="mg-l-7">สินเชื่อ</span></a
              >
            </div>
          </li>
          <li
            :class="{ active: barActive.insurance }"
            v-if="dataCfoe.insurance.length > 0"
          >
            <div
              class="arrow"
              @click="
                (barActive.insurance = !barActive.insurance),
                  (barActive.loan = false),
                  (barActive.project = false),
                  (activeBar = !activeBar),
                  (viewHover = false)
              "
            >
              <i
                :class="
                  (viewHover && activeBar) || (!viewHover && !activeBar)
                    ? 'fa-chevron-left'
                    : 'fa-chevron-right'
                "
                class="fas"
              ></i>
            </div>
            <div class="icon" @click="tabActive = 'insurance'">
              <a href="#section-product" class="text-white">
                <img src="@/assets/icon-insurance.png" alt="insurance" />
                <span class="mg-l-7">ประกันการส่งออก</span>
              </a>
            </div>
          </li>
          <li
            :class="{ active: barActive.project }"
            v-if="dataCfoe.project.length > 0"
          >
            <div
              class="arrow"
              @click="
                (barActive.project = !barActive.project),
                  (barActive.loan = false),
                  (barActive.insurance = false),
                  (activeBar = !activeBar),
                  (viewHover = false)
              "
            >
              <i
                :class="
                  (viewHover && activeBar) || (!viewHover && !activeBar)
                    ? 'fa-chevron-left'
                    : 'fa-chevron-right'
                "
                class="fas fa-chevron-left"
              ></i>
            </div>
            <div class="icon" @click="tabActive = 'project'">
              <a href="#section-product" class="text-white">
                <img src="@/assets/icon-project.png" alt="project" />
                <span class="mg-l-7">โครงการส่งเสริมธุรกิจ</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div class="mg-t-5 text-center">
        <a v-if="banner" :href="banner.url" target="_blank"
          ><img
            :src="`${imgUrl}${banner.img}`"
            class="mw-100 mg-x-auto rounded-sm"
            alt="banner"
        /></a>
      </div>
      <div class="bg-primary text-white pd-5 mg-y-3 rounded-sm">
        ภาพรวมความพร้อมด้านการส่งออก
      </div>
      <div class="text-center">
        <h3 class="mg-b-6">ชื่อบริษัท: {{ user.companyName }}</h3>
        <h4 class="text-grey mg-y-6 font-weight-normal">
          {{ assessmentName(assessmentLevel) }}
        </h4>
        <p class="text-grey-light font-20 mg-y-6">
          (กรุณาเลือกด้านต่าง ๆ เพื่อดูรายละเอียดประเมิน)
        </p>
      </div>
      <div
        class="graph-height"
        v-loading="chartLoading"
        element-loading-text="กำลังโหลด..."
      >
        <div v-if="chartLoading == false">
          <el-row :gutter="20" type="flex" class="flex-warp" align="middle">
            <el-col :lg="13" :xl="14" :offset="0">
              <radar-chart
                :data="chartData"
                :axisSize="22"
                :dataAvg="
                  compare && statusAvg && user.recommendStatus == 1
                    ? chartDataAvg
                    : []
                "
                :indicator="indicator"
                :axisLabel="true"
                :radius="widthMax >= 768 ? '70%' : '60%'"
                :nameFontSize="widthMax >= 768 ? 18 : 14"
                :height="widthMax >= 768 ? '370px' : '300px'"
                showTooltip
              />
            </el-col>
            <el-col :lg="11" :xl="10" :offset="0">
              <ul class="level-result">
                <li>Level 1 ไม่มีความพร้อม/ มีระดับความพร้อมในการส่งออกต่ำ</li>
                <li>Level 2 มีระดับความพร้อมในการส่งออกปานกลางค่อนข้างต่ำ</li>
                <li>Level 3 มีระดับความพร้อมในการส่งออกปานกลางค่อนข้างสูง</li>
                <li>Level 4 มีระดับความพร้อมในการส่งออกสูง</li>
              </ul>
            </el-col>
          </el-row>
        </div>
      </div>
      <div v-if="user.recommendStatus == 1">
        <div class="text-center pd-t-0">
          <el-tooltip
            :content="'เปรียบเทียบในประเภทธุรกิจและขนาดเดียวกัน'"
            placement="top"
          >
            <el-switch
              v-model="compare"
              active-text="เปรียบเทียบ"
              active-color="#07509C"
              @change="switchCompare"
            >
            </el-switch>
          </el-tooltip>
        </div>
        <div class="text-center pd-t-5">
          <el-button
            v-if="openMenu"
            onclick="window.open('/export/result', '_blank')"
            type="primary"
            class="rounded-sm"
          >
            <div v-on:click="onPrint">ดาวน์โหลดผลประเมิน</div>
          </el-button>
        </div>
      </div>
      <div v-else class="text-center">
        <el-button type="primary" class="rounded-sm">
          <div v-on:click="newAssessment()">ทำแบบประเมิน</div>
        </el-button>
      </div>
    </Layout>
    <div class="bg-user-second">
      <Layout>
        <div class="mg-t-1">
          <div
            v-for="item in categorieList"
            :key="item.cateId"
            class="is-flex js-between pd-5 cr-pointer cate-list mg-b-5"
            @click="
              user.recommendStatus == 1
                ? $router.push(`/result-factor?cate=${item.cateId}`)
                : (newUserModal = true)
            "
          >
            <div class="is-flex ai-center head-cate">
              {{ item.cateName }}
            </div>
            <div class="level-point">
              {{ item.point | dotRoundDown }} <i class="fas fa-angle-right"></i>
            </div>
          </div>
        </div>
        <div
          class="bg-primary text-white pd-5 mg-y-3 rounded-sm"
          v-if="featureOne || featureTwo"
        >
          ฟีเจอร์พิเศษ
        </div>
        <el-row :gutter="20">
          <el-col :span="24" class="mg-b-3">
            <a v-if="featureOne" :href="featureOne.url" target="_blank"
              ><img
                :src="`${imgUrl}${featureOne.img}`"
                class="mw-100 mg-x-auto rounded-sm"
                alt=""
            /></a>
          </el-col>
          <el-col :span="24" class="mg-b-3">
            <a
              v-if="featureTwo"
              @click="getToken(featureTwo.url)"
              target="_blank"
              ><img
                :src="`${imgUrl}${featureTwo.img}`"
                class="mw-100 mg-x-auto rounded-sm"
                alt=""
            /></a>
          </el-col>
        </el-row>
        <div class="hidden-sm-and-down" v-if="dataCfoe != null">
          <div class="bg-primary banner--parent rounded-sm">
            <div
              class="pd-5 banner-child"
              @click="tabActive = 'product'"
              :class="[{ active: tabActive == 'product' }]"
              v-if="dataCfoe.product.length > 0"
            >
              ผลิตภัณฑ์และโครงการที่เหมาะสมสำหรับคุณ
            </div>
            <div
              class="pd-5 banner-child"
              @click="tabActive = 'loan'"
              :class="[{ active: tabActive == 'loan' }]"
              v-if="dataCfoe.credit.length > 0"
            >
              สินเชื่อ
            </div>
            <div
              class="pd-5 banner-child"
              @click="tabActive = 'insurance'"
              :class="[{ active: tabActive == 'insurance' }]"
              v-if="dataCfoe.insurance.length > 0"
            >
              ประกันการส่งออก
            </div>
            <div
              class="pd-5 banner-child"
              @click="tabActive = 'project'"
              :class="[{ active: tabActive == 'project' }]"
              v-if="dataCfoe.project.length > 0"
            >
              โครงการส่งเสริมธุรกิจ
            </div>
          </div>
        </div>
      </Layout>

      <Layout class="mg-t-5" id="section-product" v-if="dataCfoe != null">
        <productComponent
          :data="dataCfoe.product"
          v-if="tabActive == 'product'"
          @fetchResult="fetchResult"
        />
        <loanComponent
          :size="assessmentLevel"
          :data="dataCfoe.credit"
          v-if="tabActive == 'loan'"
          @fetchResult="fetchResult"
        />
        <insuranceComponent
          :data="dataCfoe.insurance"
          v-if="tabActive == 'insurance'"
          @fetchResult="fetchResult"
        />
        <projectComponent
          :data="dataCfoe.project"
          v-if="tabActive == 'project'"
          @fetchResult="fetchResult"
        />
      </Layout>
    </div>
    <el-dialog :visible.sync="newUserModal" width="38%">
      <h3 class="text-center mg-b-6 mg-t-0">
        ท่านต้องการทำแบบประเมินความพร้อมตอนนี้หรือไม่
      </h3>
      <p class="mg-t-6 text-center text-grey mg-b-0 font-20">
        หากท่านเลือกไม่ประเมินตอนนี้ ระบบจะตั้งค่า Default ไว้ที่ระดับ 1
      </p>
      <div slot="footer" class="is-flex ai-center js-between">
        <a class="text-grey" @click="newUserModal = false">ยังไม่ประเมิน</a>
        <el-button type="primary" @click="newAssessment()"
          >ทำแบบประเมิน</el-button
        >
      </div>
    </el-dialog>
    <div
      class="text-white text-center hidden-md-and-up"
      v-if="dataCfoe != null"
      style="width: 100%; position: fixed; bottom: 0px; z-index: 1000"
    >
      <div
        class="banner-child-mobile"
        @click="tabActive = 'product'"
        style="padding: 6.5px 8px"
        :class="[{ active: tabActive == 'product' }]"
        v-if="dataCfoe.product.length > 0"
      >
        ผลิตภัณฑ์และโครงการที่เหมาะสมสำหรับคุณ
      </div>
      <div class="is-flex banner-mobile">
        <div
          class="banner-child-mobile"
          @click="tabActive = 'loan'"
          :class="[{ active: tabActive == 'loan' }]"
          v-if="dataCfoe.credit.length > 0"
        >
          <div><img src="@/assets/icon-loan.png" alt="loan" /></div>
          สินเชื่อ
        </div>
        <div
          class="banner-child-mobile"
          @click="tabActive = 'insurance'"
          :class="[{ active: tabActive == 'insurance' }]"
          v-if="dataCfoe.insurance.length > 0"
        >
          <div><img src="@/assets/icon-insurance.png" alt="insurance" /></div>
          ประกันการส่งออก
        </div>
        <div
          class="banner-child-mobile"
          @click="tabActive = 'project'"
          :class="[{ active: tabActive == 'project' }]"
          v-if="dataCfoe.project.length > 0"
        >
          <div>
            <img src="@/assets/icon-project.png" alt="project" />
          </div>

          โครงการส่งเสริมธุรกิจ
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>
<script>
import RadarChart from "@/echart/Radar";
import DashboardTemplate from "@/template/DashboardTemplate";
import Layout from "@/template/LayoutDashboardTemplate";
import { HTTP } from "@/service/axios";
import loanComponent from "@/components/product/Loan";
import productComponent from "@/components/product/Product";
import insuranceComponent from "@/components/product/Insurance";
import projectComponent from "@/components/product/Project";
export default {
  components: {
    DashboardTemplate,
    Layout,
    RadarChart,
    loanComponent,
    productComponent,
    insuranceComponent,
    projectComponent,
  },
  computed: {
    countBar() {
      let i = -52;
      this.dataCfoe;
      Object.keys(this.dataCfoe).map((data) => {
        this.dataCfoe[data].length > 0 ? (i += 52) : null;
      });
      return i;
    },
    branchOption() {
      return this.$store.state.branch;
    },
    statusAvg() {
      if (this.chartDataAvg.length > 0) {
        let filter = this.chartDataAvg[0].value.findIndex((a) => a > 0);
        if (filter > -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    user() {
      return this.$store.state.user;
    },
    token() {
      return this.$store.state.token;
    },
    openMenu() {
      return this.$store.state.openMenu;
    },
  },
  data() {
    return {
      viewHover: false,
      activeBar: false,
      imgUrl: process.env.VUE_APP_BANNER_URL,
      ptvUrl: process.env.VUE_APP_TPV_URL,
      banner: null,
      featureOne: null,
      featureTwo: null,
      barActive: {
        loan: true,
        insurance: true,
        project: true,
      },
      tabActive: "product",
      chartData: [],
      chartDataAvg: [],
      indicator: [],
      compare: true,
      companyName: "",
      assessmentLevel: "",
      assessmentMeanGraph: [],
      loading: true,
      chartLoading: true,
      categorieList: [],
      widthMax: window.innerWidth,
      dataCfoe: null,
      newUserModal: false,
    };
  },
  async mounted() {
    if (typeof this.$route.query.user != "undefined") {
      this.newUserModal = true;
    }
    this.companyName = this.user.companyName;
    this.fetchStatusMenu();
    await this.fetchData();
    this.getBanner();
  },
  methods: {
    async getToken ( url )
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      let res = await HTTP.get(`thailand/pavilion`).catch((e) => {
        console.log(`search/consent ERROR ${e}`);
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
      });
      const urlParams = new URL(url);
      urlParams.searchParams.set("Token", `${res.data.token}`);
      window.open(urlParams, "_blank");
    },
    async getBanner ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      await HTTP.get(`banner`)
        .then((result) => {
          if (result.data.success) {
            result.data.obj.map((value) => {
              if (value.typeBanner == null || value.typeBanner == "banner") {
                this.banner = value;
              }
              if (value.typeBanner == "featureOne") {
                this.featureOne = value;
              }
              if (value.typeBanner == "featureTwo") {
                this.featureTwo = value;
              }
            });
          }
        })
        .catch((e) => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
        });
    },
    switchCompare() {
      this.chartLoading = true;
      setTimeout(() => {
        this.chartLoading = false;
      }, 500);
    },
    newAssessment() {
      this.$store.commit("SET_USER_OBJECT", {
        key: "recommendStatus",
        value: 2,
      });
      this.$router.push("/factor");
    },
    async fetchAsseement() {
      try
      {
        HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
        let res = await HTTP.get(`choose/asseement`).catch((e) => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("fetchAsseement error", e);
        });
      } catch {
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
        console.log("fetchAsseement error", e);
      }
    },
    async assessmentMean() {
      let obj = {
        year: "all",
        businessType: "all",
        businessSize: this.assessmentLevel,
        industryType: this.user.industryType,
        cateIndustryType: this.user.cateIndustryType,
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      await HTTP.post(`dashboard/recommend`, obj)
        .then((res) => {
          if (res.data.success) {
            this.assessmentMeanGraph = res.data.obj[this.assessmentLevel];
            this.assessmentMeanGraph.cateChartAvg = [];
            for (
              let i = 1;
              i < Object.keys(this.assessmentMeanGraph).length;
              i++
            ) {
              let key = i == 1 ? 1 : i == 2 ? 5 : i == 3 ? 4 : i == 4 ? 3 : 2;

              this.assessmentMeanGraph["cate" + key].cateAvg =
                this.$options.filters.dotRoundDown(
                  this.assessmentMeanGraph["cate" + key].factor.reduce(
                    (sum, item) => sum + Number(item.point),
                    0
                  ) / this.assessmentMeanGraph["cate" + key].factor.length
                );
              this.assessmentMeanGraph.cateChartAvg[i - 1] =
                this.$options.filters.dotRoundDown(
                  Number(this.assessmentMeanGraph["cate" + key].cateAvg)
                );
            }
            this.chartDataAvg = [
              {
                value: this.assessmentMeanGraph.cateChartAvg,
                name: "เปรียบเทียบในธุรกิจและขนาดเดียวกัน",
              },
            ];
          } else {
            this.alertCatchError(res.data.data);
          }
        })
        .catch((e) => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("fetchResult error", e);
        })
        .finally(() => {
          this.loading = false;
          this.chartLoading = false;
        });
    },
    fetchStatusMenu() {
      let obj = {
        token: this.token,
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${obj.token}`;
      HTTP.get(`close/function`)
        .then((res) => {
          if (res.data.success) {
            let status = res.data.obj.status == 1 ? true : false;
            this.$store.commit("SET_OPEN_MENU", status);
          }
        })
        .catch((e) => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("FETCH_OPEN_MENU error", e.response);
        });
    },
    async composeData(res) {
      this.assessmentLevel = res.data.obj.assessmentLevel;
      this.companyName = res.data.obj.companyName;

      this.dataCfoe = res.data.resCfoe.products;
      if (res.data.resCfoe.products != null) {
        this.dataCfoe["product"] = [];

        if (res.data.resCfoe.products.credit.length > 0) {
          for (let i = 0; i < res.data.resCfoe.products.credit.length; i++) {
            this.dataCfoe["product"].push({
              ...res.data.resCfoe.products.credit[i],
              type: "credit",
            });
          }
        }

        if (res.data.resCfoe.products.insurance.length > 0) {
          for (let i = 0; i < res.data.resCfoe.products.insurance.length; i++) {
            this.dataCfoe["product"].push({
              ...res.data.resCfoe.products.insurance[i],
              type: "insurance",
            });
          }
        }

        if (res.data.resCfoe.products.project.length > 0) {
          for (let i = 0; i < res.data.resCfoe.products.project.length; i++) {
            this.dataCfoe["product"].push({
              ...res.data.resCfoe.products.project[i],
              type: "project",
            });
          }
        }

        if (
          res.data.resCfoe.products.project.length == 0 &&
          res.data.resCfoe.products.insurance.length == 0 &&
          res.data.resCfoe.products.credit.length == 0
        ) {
          this.dataCfoe = null;
        }
      }

      this.categorieList = res.data.obj.categories.sort((a, b) => {
        let modifier = 1;
        if (a.cateId < b.cateId) return -1 * modifier;
        if (a.cateId > b.cateId) return 1 * modifier;
        return 0;
      });
      this.indicator = [];
      this.chartData = [
        {
          value: [],
          name: "บริษัท: " + this.companyName,
          // label: {
          //     show: true,
          //     formatter: function (params) {
          //         return params.value.toFixed(1);
          //     }
          // }
        },
      ];
      res.data.obj.categories.forEach((item, index) => {
        if (index == 0) {
          this.indicator.push({
            name:
              this.widthMax > 768
                ? this.categorieList[index].cateName.replace("และ", "\nและ")
                : this.addNewLine(
                    this.categorieList[index].cateName,
                    index == 0 ? 100 : this.widthMax < 376 ? 6 : 10
                  ),
            max: 5,
          });
          this.chartData[0].value.push(
            this.$options.filters.dotRoundDown(
              Number(this.categorieList[index].point)
            )
          );
        } else if (index == 1) {
          this.indicator.push({
            name:
              this.widthMax > 768
                ? this.categorieList[4].cateName
                    .replace("และ", "\nและ")
                    .replace("การจัดการ", "การจัดการ\n")
                : this.addNewLine(
                    this.categorieList[4].cateName,
                    index == 0 ? 100 : this.widthMax < 376 ? 6 : 10
                  ),
            max: 5,
          });
          this.chartData[0].value.push(
            this.$options.filters.dotRoundDown(
              Number(this.categorieList[4].point)
            )
          );
        } else if (index == 2) {
          this.indicator.push({
            name:
              this.widthMax > 768
                ? this.categorieList[3].cateName.replace("และ", "\nและ")
                : this.addNewLine(
                    this.categorieList[3].cateName,
                    index == 0 ? 100 : this.widthMax < 376 ? 6 : 10
                  ),
            max: 5,
          });
          this.chartData[0].value.push(
            this.$options.filters.dotRoundDown(
              Number(this.categorieList[3].point)
            )
          );
        } else if (index == 3) {
          this.indicator.push({
            name: this.categorieList[2].cateName.replace("และ", "\nและ"),
            max: 5,
          });
          this.chartData[0].value.push(
            this.$options.filters.dotRoundDown(
              Number(this.categorieList[2].point)
            )
          );
        } else if (index == 4) {
          this.indicator.push({
            name:
              this.widthMax > 768
                ? this.categorieList[1].cateName.replace("และ", "\nและ")
                : this.addNewLine(
                    this.categorieList[1].cateName,
                    index == 0 ? 100 : this.widthMax < 376 ? 6 : 10
                  ),
            max: 5,
          });
          this.chartData[0].value.push(
            this.$options.filters.dotRoundDown(
              Number(this.categorieList[1].point)
            )
          );
        }
        // this.indicator.push({
        //   name:
        //     this.widthMax > 768
        //       ? item.cateName.replace("และ", "\nและ")
        //       : this.addNewLine(
        //           item.cateName,
        //           index == 0 ? 100 : this.widthMax < 376 ? 6 : 10
        //         ),
        //   max: 5,
        // });
        // this.chartData[0].value.push(Number(item.point.toFixed(1)));
        if (this.user.recommendStatus == 0) {
          this.chartLoading = false;
        }
        return true;
      });
    },
    async fetchData() {
      if (this.user.recommendStatus == 1) {
        await this.fetchResult();
        await this.assessmentMean();
      } else {
        await this.fetchAsseement();
        await this.fetchDefault();
      }
    },
    async fetchDefault ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      await HTTP.get(`default/result`)
        .then((res) => {
          if (res.data.success) {
            this.composeData(res);
          }
        })
        .catch((e) => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("fetchResult error", e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async fetchResult() {
      let obj = {
        userId: this.user.id,
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      await HTTP.post(`recommend/result`, obj)
        .then(async (res) => {
          if (res.data.success) {
            await this.composeData(res);
          }
        })
        .catch((e) => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("fetchResult error", e);
        });
    },
    assessmentName(size) {
      let name = "";
      switch (size) {
        case "S":
          name = "ธุรกิจขนาดเล็ก";
          break;
        case "M":
          name = "ธุรกิจขนาดกลาง";
          break;
        case "L":
          name = "ธุรกิจขนาดใหญ่";
          break;
        default:
          break;
      }

      return name;
    },
    onPrint() {
      let obj = {
        activity: "ดาวน์โหลดผลประเมิน",
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post(`create/logs`, obj)
        .then((res) => {
          if (res.data.success) {
            // let routeData = this.$router.resolve({name: 'ExportResult'});
            // window.open(routeData.href, '_blank');
            // var windowReference = window.open();
            // myService.getUrl().then(function(url) {
            //     windowReference.location = url;
            // });
            // var name = "_blank";
            // var specs = ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"];
            // const url = "/export/result";
            // const win = window.open(url, specs, name);
          }
        })
        .catch((e) => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("onPrint factor error", e);
        });
    },
  },
};
</script>
<style scoped>
@media (min-width: 600px) and (max-width: 834px) {
  .box-mk2go p {
    text-align: left;
    width: 80%;
  }
}
</style>
